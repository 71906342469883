import { OrderItem } from "@/interfaces/order";
import { Tag, TagQueryFilter } from "@/interfaces/tag";
import { i18n } from "@/plugins/i18n";

import {
  FormField,
  FormFieldTypes,
  TableFieldType,
  TableHeader
} from "helix-vue-components";

/**
 * metadata to create the table of tags by location
 * @var TableHeader[] TagBoxesTableMetadata
 */
export const TagBoxesTableMetadata: TableHeader[] = [
  {
    value: "operation_uid",
    label: i18n.t("tag_manager.order").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldImage",
    sortable: false
  },
  {
    value: "starting_tag_id",
    label: i18n.t("tag_manager.starting_tag").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "type",
    label: i18n.t("type").toString(),
    fieldComponent: TableFieldType.enumText,
    enum: {
      package: i18n.t("tag_manager.types.package").toString()
    },
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "quantity",
    label: i18n.t("quantity").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldShort",
    sortable: false
  },
  {
    value: "available",
    label: i18n.t("tag_manager.available_quantity").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "created_at",
    label: i18n.t("tag_manager.created_on").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  }
];

export const addTagMetadata: FormField[] = [
  {
    component: FormFieldTypes.textField,
    label: i18n.t("tag_manager.starting_tag_id"),
    name: "starting_tag_id",
    options: {
      validationRules: ["required", "min:24", "max:24"],
      row: 1,
      flex: 4
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("tag_manager.amount_of_tags"),
    name: "quantity",
    options: {
      validationRules: ["required"],
      row: 1,
      flex: 4
    }
  },
  {
    component: FormFieldTypes.autocomplete,
    label: i18n.t("tag_manager.tag_type"),
    name: "type",
    options: {
      validationRules: ["required"],
      row: 1,
      flex: 4,
      placeholder: "Tag Type",
      associationText: "text",
      associationValue: "value",
      selectOptions: () => [
        {
          value: "package",
          text: i18n.t("tag_manager.types.package")
        }
      ]
    }
  }
];

export const filterTagMetadata: FormField[] = [
  {
    component: FormFieldTypes.textField,
    label: i18n.t("tag_manager.starting_tag_id"),
    name: "starting_tag_id",
    options: {
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 4
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("order_number"),
    name: "operation_uid",
    options: {
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 4
    }
  }
];

export const TagsTableMetadata: TableHeader[] = [
  {
    value: "operation_uid",
    label: i18n.t("tag_manager.tag_order").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false,
    conditionalClassFn: (item: Tag) => {
      return item.available === "No" ? "tag" : "";
    }
  },
  {
    value: "tag",
    label: i18n.t("tag_manager.tag_id").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__moreLong",
    sortable: false,
    conditionalClassFn: (item: Tag) => {
      return item.available === "No" ? "tag" : "";
    }
  },
  {
    value: "type",
    label: i18n.t("type").toString(),
    fieldComponent: TableFieldType.enumText,
    enum: {
      package: i18n.t("tag_manager.types.package").toString()
    },
    class: "tdt__headers__fieldLong",
    sortable: false,
    conditionalClassFn: (item: Tag) => {
      return item.available === "No" ? "tag" : "";
    }
  },
  {
    value: "available",
    label: i18n.t("tag_manager.availability").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldImage",
    sortable: false,
    conditionalClassFn: (item: Tag) => {
      return item.available === "No" ? "tag" : "";
    }
  }
];
