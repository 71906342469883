import { TagBox, TagBoxesFilters } from "@/interfaces/tag";
import {
  filterTagMetadata,
  TagBoxesTableMetadata
} from "@/metadata/tagManager";
import { tagManagerActionService } from "@/services/tagManager.action.service";
import {
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination,
  TableSuccessModalResponse
} from "helix-vue-components";
import { DynamicFormComponent, FormField } from "helix-vue-components";
import debounce from "lodash/debounce";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AddTagBoxComponent from "./addTagBox/AddTagBox.component";
import Template from "./TagBoxes.template.vue";

@Component({
  mixins: [Template],
  components: {
    AddTagBoxComponent,
    TableComponent,
    DynamicFormComponent
  }
})
export default class TagBoxesComponent extends Vue {
  @Prop()
  public tableColors!: string[];
  @Prop()
  public tagBoxes!: TagBox[];
  @Prop({ required: true })
  public pagination!: TablePagination;
  @Prop({ default: false })
  public loading!: boolean;
  @Prop({ required: true })
  public filters!: TagBoxesFilters;

  protected tagBoxesRowActions: TableAction[] | null = null;
  protected tabBoxesHeaders: TableHeader[] = TagBoxesTableMetadata;
  protected filterFieldsConfig: FormField[] = [];
  protected currentFilter: TagBoxesFilters | null = null;
  protected onFiltersChange = debounce(this.debounceFilters, 500);

  public tagBoxCreated() {
    this.$emit("loadTagBoxes", {
      filters: this.currentFilter,
      pagination: this.pagination
    });
  }

  public changePagination(pagination: TablePagination) {
    this.$emit("loadTagBoxes", {
      filters: this.currentFilter,
      pagination
    });
  }

  protected async modalSuccessAction(
    arg: TableSuccessModalResponse
  ): Promise<void> {
    const tagBox = arg.item as TagBox;
    let confirmation = true;

    if (tagBox.assigned > 0) {
      const message = {
        title: "tag_manager.undo_confirmation_title",
        acceptButton: "yes",
        cancelButton: "no",
        text: "tag_manager.undo_confirmation_with_used_tags"
      };
      confirmation = await this.$modals.loadConfirmation(message, {
        positionX: "center",
        size: "fit"
      });
    }

    if (confirmation) {
      this.$emit("undoTagBox", tagBox);
    } else {
      arg.unselectModal();
    }
  }

  protected debounceFilters(filters: { model: TagBoxesFilters }) {
    this.currentFilter = { ...filters.model };
    this.$emit("loadTagBoxes", {
      filters: this.currentFilter,
      pagination: {
        ...this.pagination,
        currentPage: 1
      }
    });
  }

  @Watch("filters")
  protected updateCurrentFilters() {
    this.currentFilter = { ...this.filters };
  }

  protected mounted() {
    this.filterFieldsConfig = [...filterTagMetadata];
    this.currentFilter = { ...this.filters };
    this.tagBoxesRowActions = tagManagerActionService.getRowTagBoxActions({
      modalSuccessAction: this.modalSuccessAction
    });
  }
}
