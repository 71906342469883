import {
  filterTagBoxDefault,
  Tag,
  TagBox,
  TagBoxesFilters,
  TagBoxLoadData,
  TagBoxSummary,
  tagBoxSummaryDefault,
  TagsLoadData,
  tagsLoadDataDefault
} from "@/interfaces/tag";
import { pusherEvents } from "@/internal";
import { tagManagerService } from "@/services/tagManager.service";
import { Callback, PageNavAction } from "@/types/types";
import { TablePagination, TablePaginationDefault } from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./MetrcTagManager.template.vue";
import TagBoxesComponent from "./tagBoxes/TagBoxes.component";
import TagManagerSummaryComponent from "./tagManagerSummary/TagManagerSummary.component";
import TagManagerTagsComponent from "./tagManagerTags/TagManagerTags.component";

const TAG_BOXES_TAB: string = "tagBoxes";

/**
 * component to manage the tags in locations
 */
@Component({
  mixins: [Template],
  components: {
    TagManagerSummaryComponent,
    TagBoxesComponent,
    TagManagerTagsComponent
  },
  inject: ["$changes"]
})
export default class MetrcTagManagerComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Action("changeIcon", { namespace: "MegaMenuModule" })
  protected changeIcon!: Callback;
  protected currentTab = TAG_BOXES_TAB;
  protected summary: TagBoxSummary = { ...tagBoxSummaryDefault };
  protected tagBoxes: TagBox[] = [];
  protected tags: Tag[] = [];
  protected paginationTagBoxes: TablePagination = {
    ...TablePaginationDefault
  };
  protected tagBoxesFilters: TagBoxesFilters = { ...filterTagBoxDefault };
  protected paginationTags: TablePagination = { ...TablePaginationDefault };
  protected loadingSummary: boolean = false;
  protected loadingTagBoxes: boolean = false;
  protected loadingTags: boolean = false;
  protected tableColors: string[] = [
    "#f2f2f2",
    "#f2f2f2",
    "#f2f2f2",
    "#ffffff",
    "#ffffff"
  ];

  protected onChangeTab() {
    if (this.currentTab === TAG_BOXES_TAB) {
      this.loadTagBoxes({
        filters: { ...filterTagBoxDefault },
        pagination: { ...TablePaginationDefault }
      });
    } else {
      this.loadTags({ ...tagsLoadDataDefault });
    }
  }

  protected async undoTagBox(tagBox: TagBox) {
    this.loadingTagBoxes = true;
    const isSuccesfully = await tagManagerService.undo(tagBox);

    if (isSuccesfully) {
      this.loadTagBoxes({
        filters: this.tagBoxesFilters,
        pagination: this.paginationTagBoxes
      });
    } else {
      this.loadingTagBoxes = false;
    }
  }

  protected async loadTagBoxes(data: TagBoxLoadData) {
    this.loadSummary();
    this.loadingTagBoxes = true;
    this.tagBoxesFilters = { ...data.filters };
    const tagBoxes = await tagManagerService.getTagBoxes(data);
    const pagination: TablePagination = await tagManagerService.getPagination();
    this.tagBoxes = [...tagBoxes];
    this.paginationTagBoxes = { ...pagination };
    this.loadingTagBoxes = false;
  }

  protected async loadTags(data: TagsLoadData) {
    this.loadSummary();
    this.loadingTags = true;
    const tags: Tag[] = await tagManagerService.getTags(data);
    const pagination: TablePagination = await tagManagerService.getPagination();
    this.tags = tags.map(item => ({
      ...item,
      available: item.available ? "Yes" : "No"
    }));
    this.paginationTags = { ...pagination };
    this.loadingTags = false;
  }

  protected async loadSummary() {
    this.loadingSummary = true;
    const summary = await tagManagerService.getTagBoxSummary();
    if (summary != null) {
      this.summary = { ...summary };
    } else {
      this.summary = { ...tagBoxSummaryDefault };
    }
    this.loadingSummary = false;
  }

  protected mounted() {
    this.setPageNav({
      title: "tag_manager.title"
    });
    this.changeIcon(
      require("@/assets/images/icon_primary_menu_locations@2x.png")
    );
    this.onChangeTab();
    this.$changes.watch(pusherEvents.tagTouched, this.onChangeTab);
  }
}
