import { TagBoxSummary } from "@/interfaces/tag";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./TagManagerSummary.template.vue";

@Component({
  mixins: [Template]
})
export default class TagMannagerSummaryComponent extends Vue {
  @Prop({ required: true })
  protected summary!: TagBoxSummary;
}
