import { defaultAddTagInformation, TagToSave } from "@/interfaces/tag";
import { addTagMetadata } from "@/metadata/tagManager";
import { tagManagerService } from "@/services/tagManager.service";
import { DynamicFormComponent, FormField } from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import Template from "./AddTagBox.template.vue";

@Component({
  mixins: [Template],
  components: {
    DynamicFormComponent
  }
})
export default class AddTagBoxComponent extends Vue {
  protected isSaving: boolean = false;
  protected model: TagToSave | null = null;
  protected form: FormField[] = [];

  protected async save() {
    this.isSaving = true;
    const data = await (this.$refs.tagForm as DynamicFormComponent).submit();
    if (data.valid) {
      await this.saveTagItem(data.currentModel as TagToSave);
    }
    this.isSaving = false;
  }

  protected async saveTagItem(model: TagToSave) {
    const result = await tagManagerService.createTagBox(model);
    if (result) {
      this.resetForm();
      this.$emit("saved");
    }
  }

  protected mounted() {
    this.form = [...addTagMetadata];
    this.$nextTick(() => {
      this.resetForm();
    });
  }

  protected resetForm() {
    this.model = { ...defaultAddTagInformation };
    (this.$refs.tagForm as DynamicFormComponent).resetValidations();
  }
}
