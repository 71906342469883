import { render, staticRenderFns } from "./TagBoxes.template.vue?vue&type=template&id=ade50c80&scoped=true&"
var script = {}
import style0 from "./TagBoxes.template.vue?vue&type=style&index=0&id=ade50c80&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ade50c80",
  null
  
)

export default component.exports