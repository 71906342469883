import { Tag, TagBox, TagBoxRowActions } from "@/interfaces/tag";
import { i18n } from "@/plugins/i18n";
import { TableAction } from "helix-vue-components";

class TagManagerActionService {
  public getRowTagBoxActions(actions: TagBoxRowActions): TableAction[] {
    return [
      {
        icon: "fal fa-undo",
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("tag_manager.undo_confirmation").toString(),
          modalSuccessText: "yes",
          modalSuccessAction: actions.modalSuccessAction
        }
      }
    ];
  }

  public getRowActions(row: (item: Tag) => void): TableAction[] {
    return [
      {
        icon: "fal fa-trash-alt",
        action: row,
        visibleCondition: ({ item }, id) => {
          return !(item && item.available === "No");
        }
      }
    ];
  }

  public getMultiActions(
    deleteTags: (item: Tag[], id: number) => void
  ): TableAction[] {
    return [
      {
        icon: "fal fa-trash-alt",
        action: deleteTags,
        visibleCondition: () => true
      }
    ];
  }
}

export const tagManagerActionService = new TagManagerActionService();
