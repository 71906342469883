import {
  TablePagination,
  TablePaginationDefault,
  TableSuccessModalResponse
} from "helix-vue-components";

export interface TagBox {
  id: number;
  starting_tag_id: string;
  type: string;
  quantity: number;
  available: number;
  assigned: number;
  created_at: string;
  operation_uid: string;
}

export interface TagQueryFilter {
  availability: boolean | null;
  order: number | null;
  tag: number | null;
}

export interface Tag {
  id: number | null;
  tag: string;
  order?: number;
  type?: string;
  created_at: string;
  available?: string;
  finished?: string;
  status?: string;
  tag_sequence_id?: number;
}

export const defaultAddTagInformation = {
  starting_tag_id: "",
  quantity: "",
  type: "package"
};

export interface TagToSave {
  starting_tag_id: string;
  quantity: string;
  type: string;
}

export interface TagBoxSummary {
  assigned: number;
  available: number;
  reserved: number;
}

export interface TagBoxesFilters {
  starting_tag_id?: string;
  operation_uid?: string;
}

export interface TagBoxLoadData {
  filters: TagBoxesFilters;
  pagination: TablePagination;
}

export interface TagBoxRowActions {
  modalSuccessAction(arg: TableSuccessModalResponse): Promise<void>;
}

export interface TagsFilters {
  search: string | null;
  type: string | null;
  availability: string | null;
  order: string | null;
  tag: string | null;
}

export interface TagsLoadData {
  filters: TagsFilters;
  pagination: TablePagination;
}

export interface TagReservationResponse {
  requested: string[];
  reserved: string[];
  reservation: TagReservation | null;
}

export interface TagReservation {
  id: number;
  reservation_status_type_id: number;
  operation_id: number | null;
  location_id: number;
  user_id: number;
  status: string;
  end_date: string;
  created_at: string;
  updated_at: string;
}

export const tagBoxSummaryDefault: TagBoxSummary = {
  assigned: 0,
  available: 0,
  reserved: 0
};

export const filterTagBoxDefault: TagBoxesFilters = {
  starting_tag_id: "",
  operation_uid: ""
};

export const filterTagsDefault: TagsFilters = {
  search: null,
  type: null,
  availability: null,
  order: null,
  tag: null
};

export const tagsLoadDataDefault: TagsLoadData = {
  filters: { ...filterTagsDefault },
  pagination: { ...TablePaginationDefault }
};
