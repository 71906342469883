import { Tag, TagQueryFilter } from "@/interfaces/tag";
import { TagsTableMetadata } from "@/metadata/tagManager";
import { brandActionService } from "@/services/brand.action.service";
import { messagesService } from "@/services/messages.service";
import { tagManagerActionService } from "@/services/tagManager.action.service";
import { tagManagerService } from "@/services/tagManager.service";
import {
  MultiSelectConfig,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./TagManagerTags.template.vue";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  }
})
export default class TagMannagerTagsComponent extends Vue {
  @Prop()
  public tableColors!: string[];
  @Prop({ required: true })
  public pagination!: TablePagination;

  @Prop({ default: false })
  public loading!: boolean;

  public multiSelectionConfig: MultiSelectConfig = {
    disableCondition: item => Boolean(item.available === "No"),
    tooltip: "tag_manager.message_tag_delete"
  };

  @Prop()
  protected tags!: Tag[];

  protected rowActions: TableAction[] = tagManagerActionService.getRowActions(
    (item: Tag) => this.deleteTags([item] as Tag[])
  );
  protected multiActions: TableAction[] = tagManagerActionService.getMultiActions(
    this.deleteTags
  );

  protected localTags: Tag[] = this.tags;
  protected query: TagQueryFilter | null = null;

  protected selectFilter = {
    tag: null,
    order: null,
    availability: null
  };

  protected headers: TableHeader[] = TagsTableMetadata;

  public filter(tag: object) {
    this.selectFilter = {
      tag: null,
      order: null,
      availability: null
    };
    this.$emit("loadTags", {
      filters: this.selectFilter,
      pagination: { ...TablePaginationDefault }
    });
  }

  public async deleteTags(arg: Tag[]) {
    if (arg.filter(tag => tag.available === "No").length) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(this.$t("delete_warning")),
        "warning"
      );
      return;
    }

    const serverResponse = await tagManagerService.deleteMultiple(arg);
    if (serverResponse.status === 202) {
      this.$emit("loadTags", {
        filters: this.query,
        pagination: { ...TablePaginationDefault }
      });
    }
  }

  public onChangePage(pagination: TablePagination) {
    this.pagination = pagination;
    this.$emit("loadTags", {
      filters: this.selectFilter,
      pagination: this.pagination
    });
  }

  @Watch("selectFilter", { deep: true })
  public async filterQuery(query: TagQueryFilter) {
    if (
      query.tag !== null ||
      query.order !== null ||
      query.availability !== null
    ) {
      this.query = { ...query };
      this.$emit("loadTags", {
        filters: query,
        pagination: { ...TablePaginationDefault }
      });
    }
  }
}
